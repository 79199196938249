body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html{
  background-color: black;
}

body {
  height: 100%;
}

.pagi > ul > li{
  color: rgb(255, 0, 0);
}

#root > div > div > ul > li > a{
  color: blueviolet;
}
.pagi{
  position: fixed;
  margin-bottom: 50px;

left: 0;
right: 0;
top:0;
  background-color: rgb(247, 151, 35);
  color: brown;
}

textarea{
  vertical-align: middle;
}

.loginButton {
  
  position: absolute;
  top: 20px;
 
  z-index: 10000;
  background-color: transparent;
  border-radius: 5px;
  border-width: 1px;
  border-color: #ee1515;
  border-style: solid;
}
.loginButton:hover{
  background-color: #ee1515;
  border-width: 0px;
}
.faclass{
 
  color: #ee1515;
  padding: 10px;
}

.faclass:hover{
 color: aliceblue;
}
@media (max-width: 377px) {
  .faclass{
    display: none;
   }
}



.formContainer{
  text-align: center;
  background-color: pink;
  width: 80%;
  height: fit-content;
  padding-bottom: 10px;
  z-index: 999999999;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  position: fixed;

}

.formContainerSignUp{
  text-align: center;
  background-color: pink;
  width: 80%;
  height: fit-content;
  padding-bottom: 10px;
  z-index: 999999999;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
}

.loginFormCont{
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: fit-content;
}

.MakeAAccount{
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: grey;
  margin-top: 30px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 400px;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
  display: block;
  
}

@media (max-width: 575.98px) {
  .loginButton {
  right: 70px;
  padding: 5px;
  }
}
@media (min-width: 575.98px) {
  .loginButton {
  right: 20px;
  padding: 10px;
  }
}
.optionCheckbox{
  width:20px;
  height:20px; 
 margin-right:10px;
}

